import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import { generateAssetBarcode } from '../../../actions/inventory';
import { sendToastMessage } from '../../../actions/toast';
import { isArrayElementsSame } from '../../../utils/common';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import Radio from '../../../common/Form/Radio';

const formName = 'downloadQrCode';

const formatOptions = [
    { value: 'pdf', name: 'format', label: 'PDF' },
    { value: 'html', name: 'format', label: 'HTML' },
];



export default function DownloadBulkQR(props) {
    let { assetslist, tab } = props;

    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const customerIdsOfAssets = Array.isArray(assetslist) && assetslist.map((asset) => asset.customer_id);
    const isToShowHTMLAndPdfOption = assetslist.find((asset) => asset?.warehouse?.id === 9833) && assetslist.length > 1;

    let defaultValues = {
        quantity: undefined,
        property: assetslist?.length > 0 ? 'ASSET' : 'GENERATE',
        format: formatOptions[0].value,
    };

    const getProperty = (tab, assetslist) => {
        let propertyLookup = {
            assets: 'ASSET',
            spareAssets: 'SPAREASSET',
        };
        if (assetslist?.length > 0) return propertyLookup[tab];
        return 'GENERATE';
    };

    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const assets = assetslist.map((i) => i.id);

    const onSubmit = (formData) => {
        const isSameCustomerIds = isArrayElementsSame(customerIdsOfAssets);
        if (!isSameCustomerIds && assetslist.length > 0) return dispatch(sendToastMessage({ status: 'danger', message: 'Multiple customer assets found' }));

        const customerId = customerIdsOfAssets[0];

        let { property, quantity, format } = formData;
        if (property === 'GENERATE' && !['', undefined, null].includes(quantity) && !isNaN(quantity) && parseInt(quantity) > 0) {
            dispatch(generateAssetBarcode({ data: { quantity: parseInt(quantity), customerId, property: getProperty(tab, assetslist), format }, formName }));
        } else {
            if (!isNaN(customerId) && parseInt(customerId) > 0) {
                dispatch(generateAssetBarcode({ data: { assets, customerId, property: getProperty(tab, assetslist), quantity: assetslist.length, format }, formName }));
            } else {
                alert('customerId required');
            }
        }
    };

    const printQrForm = (assetslist) => {
        if (Array.isArray(assetslist) && assetslist.length > 0) {
            return (
                <>
                    {isToShowHTMLAndPdfOption && <div className='px-5 pt-5 text-scogoprimary text-font13'>
                        <Radio name='format' options={formatOptions} />
                    </div>}
                    <div className={`px-5  pb-5 flex justify-center text-scogoorange text-font13 ${isToShowHTMLAndPdfOption ? '' : 'pt-5'}`}>Click Print to download pdf</div>
                </>


            );
        }
        return (
            <div className='w-full px-3'>
                <Input type={'number'} label={'Quantity'} name={'quantity'} required pattern={/^[1-9][0-9]*$/} />
            </div>
        );
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <>
            <Form
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                onCancel={closeModal}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                methods={methods}
                submitButtonText={'Print'}
            >
                {printQrForm(assetslist)}
            </Form>
        </>
    );
}

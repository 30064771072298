import React from 'react';
import { isCluster, isCustomerGroup, isPsp, isScm, isSuperVisor } from '../../../utils/role';
import IconToolTip from '../../../common/IconToolTip';
import { useSelector, useDispatch } from 'react-redux';
import { copyToClipboard } from '../../../actions/utils';
import { LineClampComponent } from '../TicketTableComponent';
import StatusLabel from '../../Payments/reusableComponents/StatusLabel';
import { checkLatLong, getLookupWitHDefaultValue, validateStringValue } from '../../../utils/common';
import Checkbox from '../../../common/Checkbox';
import { ticketStatusList } from '../TicketController';
import { openCustomModal } from '../../../actions/modal';
import NotifyTicketDetailsToEndCustomer from '../Modal/NotifyTicketDetailsToEndCustomer';


export const cityTierClass = getLookupWitHDefaultValue({
    lookup: {
        T1: 'scogoclosed',
        T2: 'scogoorange',
        T3: 'scogoBasicYellow',
        T4: 'scogobgsky',
    },
    defaultValue: () => 'scogoclosed',
});

export default function SiteColumn({ ticket, showCheckBox, rowIndex, checkedTicketTrackerLocal, handleOnChangeCheckboxLocal }) {
    const { loggedUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const type = loggedUser.type;
    const role = loggedUser.role.id;
    const isLocalDetailsAvailable = ticket.local_contact_name || ticket.local_contact_mobile || ticket.local_contact_mail;
    const localContact = `${ticket.local_contact_name}${ticket.local_contact_mobile ? `, ${ticket.local_contact_mobile}` : ''}`;
    const showTierCitiesLabel = isCustomerGroup(role, type) || isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type) || isPsp(role, type);

    const copySiteAddress = (ticket) => {
        const district = validateStringValue(ticket?.district?.name);
        const locality = validateStringValue(ticket?.locality?.name);
        const enduser_name = validateStringValue(ticket?.enduser_name);
        let data = `${ticket.site_address}, ${ticket.job_fk_pincode_id}, ${ticket.city}, ${ticket.state}, ${district} ${locality} \nLink: https://maps.google.com?q=${checkLatLong(ticket.latitude) && checkLatLong(ticket.longitude) ? `${ticket.latitude},${ticket.longitude}` : `${ticket.site_address}, ${ticket.job_fk_pincode_id}`} 
        ${checkLatLong(ticket.latitude) && checkLatLong(ticket.longitude) ? `\n LatLong:${ticket.latitude},${ticket.longitude}` : ""}`
        if (enduser_name) data = `${data}, Enduser : ${enduser_name}`
        dispatch(copyToClipboard({ data }));
    }

    const siteAddress = `${ticket.site_address}, ${ticket?.job_fk_pincode_id}, ${ticket?.city}, ${ticket?.state}`;


    const onEditClick = () => {
        const heading = 'Notify End Customer';
        const ticket_status = ticketStatusList.find((item) => item.check(ticket, { role, type }));
        const modalComponent = <NotifyTicketDetailsToEndCustomer site_id={ticket.external_site_id} address={ticket.site_address} job_ticket_number={ticket?.job_ticket_number} mobile={`${ticket?.local_contact_mobile}`} ticketId={ticket?.ticket_id} status={ticket_status ? ticket_status.label : ''} />;
        dispatch(openCustomModal({
            heading,
            modalWidth: '50rem',
            modalHeight: 'auto',
            modalComponent,
        }));
    }

    const showNotifyEndCustomer = isScm(role, type);

    return (<div className="w-full flex break-all">
        {showCheckBox ? <div className='max-w-max pl-2 items-center flex'>
            <Checkbox
                checkColor='text-scogoorange mr-2'
                checked={checkedTicketTrackerLocal.has(rowIndex)}
                onChange={() => {
                    handleOnChangeCheckboxLocal(rowIndex);
                }}
                dynamicSize={'1.2rem'}
            />
        </div> : <></>}
        <div className='w-11/12'>
            <div className='flex w-full gap-1'>
                {ticket.external_site_id.length > 0 && (
                    <IconToolTip title={ticket.external_site_id} placement='bottom'>
                        <p className={`cursor-pointer truncate  text-font10 text-black`}>
                            Site Id : <span className={'text-font10 text-black w-full font-normal font-medium text-font11'}>{ticket.external_site_id}</span>
                        </p>
                    </IconToolTip>
                )}

                {ticket?.external_site_id?.length === 0 && ticket?.site_branch_name?.length === 0 && (
                    <p className={`cursor-pointer truncate text-font10 text-black`}>Site Id :</p>
                )}

                {ticket.external_site_id && (
                    <IconToolTip placement={'bottom'} title={`Copy Site Id`}>
                        <span
                            className='cursor-pointer material-icons text-scogobgsky text-lg'
                            onClick={() => dispatch(copyToClipboard({ data: `${ticket.external_site_id}` }))}
                        >
                            content_copy
                        </span>
                    </IconToolTip>
                )}
            </div>
            <div>
                {ticket.site_branch_name.length > 1 && (
                    <IconToolTip title={`Branch Name : ${ticket.site_branch_name}`} placement='bottom'>
                        <p className={`cursor-pointer truncate  text-font10 text-black`}>
                            Branch : <span className={'text-font10 text-black w-full font-normal font-medium text-font10'}>{ticket.site_branch_name}</span>
                        </p>
                    </IconToolTip>
                )}
            </div>

            {isLocalDetailsAvailable && <div className='flex items-center'>

                <IconToolTip title={`Local Contact : ${localContact}`} >
                    <div className='line-clamp-1 text-scogobgsky text-left text-font10 hover:text-scogoorange'>
                        <span className={'text-black'}>LC : </span>
                        <span>{localContact}</span>
                    </div>
                </IconToolTip>

                {showNotifyEndCustomer && <IconToolTip title='Notify End Customer'>
                    <span onClick={onEditClick} className='hover:scale-105 transition-all duration-300 material-icons text-scogoorange text-lg cursor-pointer'>notifications</span>
                </IconToolTip>}
            </div>}

            <div className='w-full'>
                {ticket.site_address && <IconToolTip placement={'bottom'} title={<SiteDetailsPopup siteAddress={siteAddress} ticket={ticket} copySiteAddress={copySiteAddress} dispatch={dispatch} isLocalDetailsAvailable={isLocalDetailsAvailable} localContact={localContact} showTierCitiesLabel={showTierCitiesLabel} cityTierClass={cityTierClass} />}>
                    <span className='text-scogogray text-font11 italic line-clamp-2'>
                        {siteAddress}
                    </span>
                </IconToolTip>}


                <div className='flex items-center'>
                    {ticket.locality && <IconToolTip title='Locality'>
                        <span className='text-black text-font11 max-w-8/12 truncate'>{`${ticket.locality.name}`}</span>
                    </IconToolTip>}
                    {ticket.district && <IconToolTip title='District'>
                        <span className='text-black text-font11 max-w-8/12 truncate'>{`${ticket.locality ? ', ' : ''} ${ticket.district.name}`}</span>
                    </IconToolTip>
                    }
                </div>
                <div className='w-full flex '>
                    {ticket.city && <IconToolTip title='Pincode, City'>
                        <span className='text-black text-font11 max-w-8/12 truncate'>{`${ticket?.job_fk_pincode_id}, ${ticket.city}`}</span>
                    </IconToolTip>}
                    {(ticket.site_address || ticket.city) && (
                        <>
                            <IconToolTip placement={'bottom'} title={`Open Location`}>

                                <span className='cursor-pointer material-icons text-scogobgsky text-lg' onClick={() => {
                                    if (ticket.latitude && ticket.longitude && ticket.fk_project_id === 2263) {
                                        window.open(`https://maps.google.com?q=${`${ticket.latitude},${ticket.longitude}`}`, '_blank');
                                    } else {
                                        window.open(`https://maps.google.com?q=${`${ticket.site_address}, ${ticket?.job_fk_pincode_id}, ${ticket.city}`}`, '_blank');
                                    }
                                }}>
                                    place
                                </span>
                            </IconToolTip>
                            <IconToolTip placement={'bottom'} title={`Copy Address`}>
                                <span
                                    className='cursor-pointer material-icons text-scogobgsky text-lg'
                                    onClick={() => copySiteAddress(ticket)}
                                >
                                    content_copy
                                </span>
                            </IconToolTip>
                        </>
                    )}
                    {showTierCitiesLabel && ticket.cityTier && (
                        <span className='w-4/12 truncate '>
                            <StatusLabel
                                titleText='City Tier'
                                labelClass={'bg-scogolightblue'}
                                content={ticket.cityTier}
                                color={cityTierClass[ticket.cityTier]}
                            />
                        </span>
                    )}
                </div>
            </div>
        </div>
    </div>
    );
}


const SiteDetailsPopup = ({ siteAddress, ticket, copySiteAddress, dispatch, isLocalDetailsAvailable, localContact, showTierCitiesLabel, cityTierClass }) => {
    return <div className='w-full pr-2'>
        <div className='flex w-full gap-1'>
            {ticket.external_site_id.length > 0 && (
                <p className={`cursor-pointer truncate  text-font10 text-scogoorange`}>
                    Site Id : <span className={'text-font10 text-white w-full font-normal font-medium text-font11'}>{ticket.external_site_id}</span>
                </p>
            )}

            {ticket?.external_site_id?.length === 0 && ticket?.site_branch_name?.length === 0 && (
                <p className={`cursor-pointer truncate text-font10 text-scogoorange`}>Site Id :</p>
            )}

            {ticket.external_site_id && (
                <span
                    className='cursor-pointer material-icons text-scogobgsky text-lg'
                    onClick={() => dispatch(copyToClipboard({ data: `${ticket.external_site_id}` }))}
                >
                    content_copy
                </span>
            )}
        </div>
        <div>
            {ticket.site_branch_name.length > 1 && (
                <p className={`cursor-pointer truncate  text-font10 text-scogoorange`}>
                    Branch : <span className={'text-font10 text-white w-full font-normal font-medium text-font10'}>{ticket.site_branch_name}</span>
                </p>
            )}
        </div>

        {isLocalDetailsAvailable &&
            <div className='text-white line-clamp-1 cursor-pointer text-left text-font10 hover:text-scogoorange'>
                <span className={'text-scogoorange'}>LC : </span>{localContact}
            </div>
        }

        <div className='w-full'>
            {ticket.site_address &&
                <span className='text-white text-font11 italic lien-clamp-2'>{siteAddress}</span>
            }

            <div className='flex items-center'>
                {ticket.locality &&
                    <span className='text-white text-font11 max-w-8/12 truncate'>{`${ticket.locality.name}`}</span>
                }
                {ticket.district &&
                    <span className='text-white text-font11 max-w-8/12 truncate'>{`${ticket.locality ? ', ' : ''} ${ticket.district.name}`}</span>
                }
            </div>
            <div className='w-full flex '>
                {ticket.city && <IconToolTip title='Pincode, City'>
                    <span className='text-white text-font11 max-w-8/12 truncate'>{`${ticket?.job_fk_pincode_id}, ${ticket.city}`}</span>
                </IconToolTip>}
                {(ticket.site_address || ticket.city) && (
                    <>
                        <IconToolTip placement={'bottom'} title={`Open Location`}>
                            <span className='cursor-pointer material-icons text-scogobgsky text-lg' onClick={() => {
                                window.open(`https://maps.google.com?q=${`${ticket.site_address}, ${ticket?.job_fk_pincode_id}, ${ticket.city}`}`, '_blank');
                            }}>
                                place
                            </span>
                        </IconToolTip>
                        <IconToolTip placement={'bottom'} title={`Copy Address`}>
                            <span
                                className='cursor-pointer material-icons text-scogobgsky text-lg'
                                onClick={() => copySiteAddress(ticket)}
                            >
                                content_copy
                            </span>
                        </IconToolTip>
                    </>
                )}
                {showTierCitiesLabel && ticket.cityTier && (
                    <span className='w-4/12 truncate pl-1'>
                        <StatusLabel
                            titleText='City Tier'
                            labelClass={'bg-scogolightblue'}
                            content={ticket.cityTier}
                            color={cityTierClass[ticket.cityTier]}
                        />
                    </span>
                )}
            </div>
        </div>
    </div>
}
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import ButtonScogoClosedOutlined from '../../../common/Buttons/ButtonScogoClosedOutlined';



export default function ViewTicketOwner({ owners }) {
    const dispatch = useDispatch();
    return (
        <div className='w-full'>

            <div className='px-4 flex gap-2 flex-wrap py-4'>
                {owners.map((owner) => {
                    return <div className=''>
                        <span className='bg-tagbg scogoprimary text-[13px] px-4 py-2 rounded-3px max-w-max text-ellipsis truncate'>{owner.owner_name}</span>
                    </div>
                })}
            </div>
            <div className='px-4 flex gap-2 flex-wrap py-4 justify-end'>
                <ButtonScogoClosedOutlined textOrComponent='Close' onClick={() => {
                    dispatch(closeModalAction());
                }} />
            </div>
        </div>
    )
}

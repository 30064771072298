import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import { assignTicketOwnerShipAction, getPossibleTicketOwners } from '../../../actions/tickets';

const formName = 'AssignTicketOwnerShip';

export default function AssignTicketOwnerShip({ owners, ticketId, projectId, customerId }) {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { isPossibleTicketOwnersLoading } = useSelector((state) => state.loading);

    useEffect(() => {
        dispatch(getPossibleTicketOwners({ projectId, customerId }));
    }, [projectId, customerId]);

    const submit = (formValues) => {
        const assignTo = formValues.assign_to.map((owner) => owner.value);

        const payload = {
            ticketId,
            ownerIds: assignTo,
            remark: formValues.remark,
            formName
        }
        dispatch(assignTicketOwnerShipAction(payload));
    };


    const methods = useForm({ defaultValues: {}, mode: 'all' });
    const { possibleTicketOwners } = useSelector((state) => state.tickets);

    const possibleTicketOwnersOptions = useMemo(() => {
        if (possibleTicketOwners && possibleTicketOwners.length > 0) {
            return possibleTicketOwners.map((owner) => ({
                label: owner.name,
                value: owner.id,
                key: owner.id
            }));
        }
        return [];
    }, [possibleTicketOwners]);


    useEffect(() => {
        if (possibleTicketOwnersOptions && possibleTicketOwnersOptions.length > 0 && owners && owners.length > 0) {
            const allOwners = owners.map((owner) => owner.owner_id);
            const find = possibleTicketOwnersOptions.filter((possibleOwner) => allOwners.includes(possibleOwner.value));
            methods.setValue('assign_to', find);
        }
    }, [possibleTicketOwnersOptions, owners]);

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-2 flex gap-2'>
                <Select
                    label='Assign To'
                    name='assign_to'
                    options={possibleTicketOwnersOptions}
                    required
                    isMulti
                    className='w-full'
                    isLoading={isPossibleTicketOwnersLoading}
                />
                <Input
                    label='Remark'
                    type={'text'}
                    name='remark'
                    className='w-full'
                    required
                />
            </div>
        </Form>
    );
}

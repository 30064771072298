export const REMOVE_FROM_TICKETS_STORE_REQUEST = 'REMOVE_FROM_TICKETS_STORE_REQUEST';

export const BOQ_LIST_REQUEST = 'BOQ_LIST_REQUEST';
export const BOQ_LIST_SUCCESS = 'BOQ_LIST_SUCCESS';
export const BOQ_LIST_FAILURE = 'BOQ_LIST_FAILURE';

export const UPDATE_TICKET_PROPERTIES_REQUEST = 'UPDATE_TICKET_PROPERTIES_REQUEST';
export const UPDATE_TICKET_PROPERTIES_SUCCESS = 'UPDATE_TICKET_PROPERTIES_SUCCESS';
export const UPDATE_TICKET_PROPERTIES_FAILURE = 'UPDATE_TICKET_PROPERTIES_FAILURE';

export const CREATE_SUPPORT_TICKETS_REQUEST = 'CREATE_SUPPORT_TICKETS_REQUEST';
export const CREATE_SUPPORT_TICKETS_SUCCESS = 'CREATE_SUPPORT_TICKETS_SUCCESS';
export const CREATE_SUPPORT_TICKETS_FAILURE = 'CREATE_SUPPORT_TICKETS_FAILURE';

export const CREATE_INDEPENDENT_TICKETS_REQUEST = 'CREATE_INDEPENDENT_TICKETS_REQUEST';
export const CREATE_INDEPENDENT_TICKETS_SUCCESS = 'CREATE_INDEPENDENT_TICKETS_SUCCESS';
export const CREATE_INDEPENDENT_TICKETS_FAILURE = 'CREATE_INDEPENDENT_TICKETS_FAILURE';

export const NOTIFY_TICKETS_TO_ISP_REQUEST = 'NOTIFY_TICKETS_TO_ISP_REQUEST';
export const NOTIFY_TICKETS_TO_ISP_SUCCESS = 'NOTIFY_TICKETS_TO_ISP_SUCCESS';
export const NOTIFY_TICKETS_TO_ISP_FAILURE = 'NOTIFY_TICKETS_TO_ISP_FAILURE';

export const SP_ASSIGNED_NEW_TICKETS_LIST_REQUEST = 'SP_ASSIGNED_NEW_TICKETS_LIST_REQUEST';
export const SP_ASSIGNED_NEW_TICKETS_LIST_SUCCESS = 'SP_ASSIGNED_NEW_TICKETS_LIST_SUCCESS';
export const SP_ASSIGNED_NEW_TICKETS_LIST_FAILURE = 'SP_ASSIGNED_NEW_TICKETS_LIST_FAILURE';

export const EXEC_DATECHANGE_REMARKS_REQUEST = 'EXEC_DATECHANGE_REMARKS_REQUEST';
export const EXEC_DATECHANGE_REMARKS_SUCCESS = 'EXEC_DATECHANGE_REMARKS_SUCCESS';
export const EXEC_DATECHANGE_REMARKS_FAILURE = 'EXEC_DATECHANGE_REMARKS_FAILURE';

export const ACCEPT_SP_ASSIGNED_TICKET_REQUEST = 'ACCEPT_SP_ASSIGNED_TICKET_REQUEST';
export const ACCEPT_SP_ASSIGNED_TICKET_FAILURE = 'ACCEPT_SP_ASSIGNED_TICKET_FAILURE';

export const RESPOND_FEASIBILITY_TICKET_REQUEST = 'RESPOND_FEASIBILITY_TICKET_REQUEST';
export const RESPOND_FEASIBILITY_TICKET_SUCCESS = 'RESPOND_FEASIBILITY_TICKET_SUCCESS';
export const RESPOND_FEASIBILITY_TICKET_FAILURE = 'RESPOND_FEASIBILITY_TICKET_FAILURE';

export const SP_ONGOING_TICKETS_REQUEST = 'SP_ONGOING_TICKETS_REQUEST';
export const SP_ONGOING_TICKETS_SUCCESS = 'SP_ONGOING_TICKETS_SUCCESS';
export const SP_ONGOING_TICKETS_FAILURE = 'SP_ONGOING_TICKETS_FAILURE';

export const VIEW_TICKET_REQUEST = 'VIEW_TICKET_REQUEST';
export const VIEW_TICKET_SUCCESS = 'VIEW_TICKET_SUCCESS';
export const VIEW_TICKET_FAILURE = 'VIEW_TICKET_FAILURE';

export const GET_EXECUTION_DATE_TIMELINE_REQUEST = 'GET_EXECUTION_DATE_TIMELINE_REQUEST';
export const GET_EXECUTION_DATE_TIMELINE_SUCCESS = 'GET_EXECUTION_DATE_TIMELINE_SUCCESS';
export const GET_EXECUTION_DATE_TIMELINE_FAILURE = 'GET_EXECUTION_DATE_TIMELINE_FAILURE';

export const UPDATE_TICKET_COURIER_STATUS_REQUEST = 'UPDATE_TICKET_COURIER_STATUS_REQUEST';
export const UPDATE_TICKET_COURIER_STATUS_SUCCESS = 'UPDATE_TICKET_COURIER_STATUS_SUCCESS';
export const UPDATE_TICKET_COURIER_STATUS_FAILURE = 'UPDATE_TICKET_COURIER_STATUS_FAILURE';

export const CLEAR_PAYMENTS_SIDEBAR_DATA_FROM_STORE = 'CLEAR_PAYMENTS_SIDEBAR_DATA_FROM_STORE';

export const GET_CHILD_TICKETS_REQUEST = 'GET_CHILD_TICKETS_REQUEST';
export const GET_CHILD_TICKETS_SUCCESS = 'GET_CHILD_TICKETS_SUCCESS';
export const GET_CHILD_TICKETS_FAILURE = 'GET_CHILD_TICKETS_FAILURE';

export const SOW_DOCUMENT_REQUEST = 'SOW_DOCUMENT_REQUEST';
export const SOW_DOCUMENT_NOT_FOUND = 'SOW_DOCUMENT_NOT_FOUND';
export const SOW_DOCUMENT_FAILURE = 'SOW_DOCUMENT_FAILURE';

export const SIGN_OFF_SAMPLE_REPORT_REQUEST = 'SIGN_OFF_SAMPLE_REPORT_REQUEST';
export const SIGN_OFF_SAMPLE_REPORT_SUCCESS = 'SIGN_OFF_SAMPLE_REPORT_SUCCESS';
export const SIGN_OFF_SAMPLE_REPORT_NOT_FOUND = 'SIGN_OFF_SAMPLE_REPORT_NOT_FOUND';
export const SIGN_OFF_SAMPLE_REPORT_FAILURE = 'SIGN_OFF_SAMPLE_REPORT_FAILURE';
export const GET_TICKET_DETAILS_REQUEST = 'GET_TICKET_DETAILS_REQUEST';
export const GET_TICKET_DETAILS_SUCCESS = 'GET_TICKET_DETAILS_SUCCESS';
export const GET_TICKET_DETAILS_FAILURE = 'GET_TICKET_DETAILS_FAILURE';

export const GET_TICKET_DETAILS_FOR_DETAIL_PAGE_REQUEST = 'GET_TICKET_DETAILS_FOR_DETAIL_PAGE_REQUEST';
export const GET_TICKET_DETAILS_FOR_DETAIL_PAGE_FAILURE = 'GET_TICKET_DETAILS_FOR_DETAIL_PAGE_FAILURE';
export const GET_TICKET_DETAILS_FOR_DETAIL_PAGE_SUCCESS = 'GET_TICKET_DETAILS_FOR_DETAIL_PAGE_SUCCESS';

export const EDIT_EXECUTION_DATE_REQUEST = 'EDIT_EXECUTION_DATE_REQUEST';
export const EDIT_EXECUTION_DATE_SUCCESS = 'EDIT_EXECUTION_DATE_SUCCESS';
export const EDIT_EXECUTION_DATE_FAILURE = 'EDIT_EXECUTION_DATE_FAILURE';

export const TICKET_REPORT_LINK_REQUEST = 'TICKET_REPORT_LINK_REQUEST';
export const TICKET_REPORT_LINK_SUCCESS = 'TICKET_REPORT_LINK_SUCCESS';
export const TICKET_REPORT_LINK_NOT_FOUND = 'TICKET_REPORT_LINK_NOT_FOUND';
export const TICKET_REPORT_LINK_FAILURE = 'TICKET_REPORT_LINK_FAILURE';

export const CLOSE_TICKET_REQUEST = 'CLOSE_TICKET_REQUEST';
export const CLOSE_TICKET_SUCCESS = 'CLOSE_TICKET_SUCCESS';
export const CLOSE_TICKET_FAILURE = 'CLOSE_TICKET_FAILURE';

export const EMAIL_REPORT_REQUEST = 'EMAIL_REPORT_REQUEST';
export const EMAIL_REPORT_SUCCESS = 'EMAIL_REPORT_SUCCESS';
export const EMAIL_REPORT_FAILURE = 'EMAIL_REPORT_FAILURE';

export const GET_TICKET_PAYMENTS_REQUEST = 'GET_TICKET_PAYMENTS_REQUEST';
export const GET_TICKET_PAYMENTS_SUCCESS = 'GET_TICKET_PAYMENTS_SUCCESS';
export const GET_TICKET_BASE_PAYMENTS_SUCCESS = 'GET_TICKET_BASE_PAYMENTS_SUCCESS';
export const GET_TICKET_PAYMENTS_FAILURE = 'GET_TICKET_PAYMENTS_FAILURE';

export const CLEANUP_TICKET_DETAILS = 'CLEANUP_TICKET_DETAILS';
export const CLEANUP_TICKET_PAYMENT_DETAILS = 'CLEANUP_TICKET_PAYMENT_DETAILS';

export const REQUEST_ADDITIONAL_AMOUNT_REQUEST = 'REQUEST_ADDITIONAL_AMOUNT_REQUEST';
export const REQUEST_ADDITIONAL_AMOUNT_SUCCESS = 'REQUEST_ADDITIONAL_AMOUNT_SUCCESS';
export const REQUEST_ADDITIONAL_AMOUNT_FAILURE = 'REQUEST_ADDITIONAL_AMOUNT_FAILURE';

export const PROCESS_ADDITIONAL_AMOUNT_REQUEST = 'PROCESS_ADDITIONAL_AMOUNT_REQUEST';
export const PROCESS_ADDITIONAL_AMOUNT_SUCCESS = 'PROCESS_ADDITIONAL_AMOUNT_SUCCESS';
export const PROCESS_ADDITIONAL_AMOUNT_FAILURE = 'PROCESS_ADDITIONAL_AMOUNT_FAILURE';

export const APPROVE_TICKET_SIGNOFF_REQUEST = 'APPROVE_TICKET_SIGNOFF_REQUEST';
export const APPROVE_TICKET_SIGNOFF_SUCCESS = 'APPROVE_TICKET_SIGNOFF_SUCCESS';
export const APPROVE_TICKET_SIGNOFF_FAILURE = 'APPROVE_TICKET_SIGNOFF_FAILURE';

export const GET_TICKET_LIST_REQUEST = 'GET_TICKET_LIST_REQUEST';
export const GET_TICKET_LIST_SUCCESS = 'GET_TICKET_LIST_SUCCESS';
export const GET_TICKET_LIST_FAILURE = 'GET_TICKET_LIST_FAILURE';

export const PUT_TO_TICKETS_STORE = 'PUT_TO_TICKETS_STORE';

export const TICKETS_TODAY_STATUS_LIST_REQUEST = 'TICKETS_TODAY_STATUS_LIST_REQUEST';
export const TICKETS_TODAY_STATUS_LIST_SUCCESS = 'TICKETS_TODAY_STATUS_LIST_SUCCESS';
export const TICKETS_TODAY_STATUS_LIST_FAILURE = 'TICKETS_TODAY_STATUS_LIST_FAILURE';
export const TICKETS_TODAY_STATUS_COUNT_SUCCESS = 'TICKETS_TODAY_STATUS_COUNT_SUCCESS';

export const ADD_FE_INTENTION_STATUS_REQUEST = 'ADD_FE_INTENTION_STATUS_REQUEST';
export const ADD_FE_INTENTION_STATUS_FAILURE = 'ADD_FE_INTENTION_STATUS_FAILURE';

export const INITIATE_TICKET_SUCCESS = 'INITIATE_TICKET_SUCCESS';
export const INITIATE_TICKET_REQUEST = 'INITIATE_TICKET_REQUEST';
export const INITIATE_TICKET_FAILURE = 'INITIATE_TICKET_FAILURE';

export const UPDATE_NOC_EXECUTION_DATA_REQUEST = 'UPDATE_NOC_EXECUTION_DATA_REQUEST';
export const UPDATE_NOC_EXECUTION_DATA_FAILURE = 'UPDATE_NOC_EXECUTION_DATA_FAILURE';

export const UPDATE_TODAY_STATUS_TICKET_PROPERTIES_REQUEST = 'UPDATE_TODAY_STATUS_TICKET_PROPERTIES_REQUEST';
export const UPDATE_TODAY_STATUS_TICKET_PROPERTIES_FAILURE = 'UPDATE_TODAY_STATUS_TICKET_PROPERTIES_FAILURE';

export const SET_TODAY_STATUS_FILTER_AND_PAGINATION_DATA = 'SET_TODAY_STATUS_FILTER_AND_PAGINATION_DATA';

export const GET_TODAY_STATUS_FILTER_DATA_SUCCESS = 'GET_TODAY_STATUS_FILTER_DATA_SUCCESS';
export const GET_TODAY_STATUS_FILTER_DATA_REQUEST = 'GET_TODAY_STATUS_FILTER_DATA_REQUEST';
export const GET_TODAY_STATUS_FILTER_DATA_FAILURE = 'GET_TODAY_STATUS_FILTER_DATA_FAILURE';

export const GET_SP_ACCEPTED_TICKETS_REQUEST = 'GET_SP_ACCEPTED_TICKETS_REQUEST';
export const GET_SP_ACCEPTED_TICKETS_SUCCESS = 'GET_SP_ACCEPTED_TICKETS_SUCCESS';
export const GET_SP_ACCEPTED_TICKETS_FAILURE = 'GET_SP_ACCEPTED_TICKETS_FAILURE';

export const GET_FE_ACCEPTED_TICKETS_REQUEST = 'GET_FE_ACCEPTED_TICKETS_REQUEST';
export const GET_FE_ACCEPTED_TICKETS_SUCCESS = 'GET_FE_ACCEPTED_TICKETS_SUCCESS';
export const GET_FE_ACCEPTED_TICKETS_FAILURE = 'GET_FE_ACCEPTED_TICKETS_FAILURE';

export const GET_SP_TICKETS_COUNT_REQUEST = 'GET_SP_TICKETS_COUNT_REQUEST';
export const GET_SP_TICKETS_COUNT_SUCCESS = 'GET_SP_TICKETS_COUNT_SUCCESS';
export const GET_SP_TICKETS_COUNT_FAILURE = 'GET_SP_TICKETS_COUNT_FAILURE';

export const GET_FE_TICKETS_COUNT_REQUEST = 'GET_FE_TICKETS_COUNT_REQUEST';
export const GET_FE_TICKETS_COUNT_SUCCESS = 'GET_FE_TICKETS_COUNT_SUCCESS';
export const GET_FE_TICKETS_COUNT_FAILURE = 'GET_FE_TICKETS_COUNT_FAILURE';

export const GET_TICKET_FILTER_LIST_REQUEST = 'GET_TICKET_FILTER_LIST_REQUEST';
export const GET_TICKET_FILTER_LIST_FAILURE = 'GET_TICKET_FILTER_LIST_FAILURE';
export const GET_TICKET_FILTER_LIST_SUCCESS = 'GET_TICKET_FILTER_LIST_SUCCESS';

export const GET_TICKET_FILTERED_LIST_REQUEST = 'GET_TICKET_FILTERED_LIST_REQUEST';
export const GET_TICKET_FILTERED_LIST_SUCCESS = 'GET_TICKET_FILTERED_LIST_SUCCESS';
export const GET_TICKET_FILTERED_LIST_FAILURE = 'GET_TICKET_FILTERED_LIST_FAILURE';

export const GET_SP_NEW_TICKETS_LIST_REQUEST = 'GET_SP_NEW_TICKETS_LIST_REQUEST';
export const GET_SP_NEW_TICKETS_LIST_SUCCESS = 'GET_SP_NEW_TICKETS_LIST_SUCCESS';
export const GET_SP_NEW_TICKETS_LIST_FAILURE = 'GET_SP_NEW_TICKETS_LIST_FAILURE';

export const GET_ASSETS_CONFIG_TEMPLATE_REQUEST = 'GET_ASSETS_CONFIG_TEMPLATE_REQUEST';
export const GET_ASSETS_CONFIG_TEMPLATE_SUCCESS = 'GET_ASSETS_CONFIG_TEMPLATE_SUCCESS';
export const GET_ASSETS_CONFIG_TEMPLATE_FAILURE = 'GET_ASSETS_CONFIG_TEMPLATE_FAILURE';

export const GET_TICKET_ASSETS_REMARKS_REQUEST = 'GET_TICKET_ASSETS_REMARKS_REQUEST';
export const GET_TICKET_ASSETS_REMARKS_SUCCESS = 'GET_TICKET_ASSETS_REMARKS_SUCCESS';
export const GET_TICKET_ASSETS_REMARKS_FAILURE = 'GET_TICKET_ASSETS_REMARKS_FAILURE';

export const GET_TICKET_DETAILS_BY_ID_REQUEST = 'GET_TICKET_DETAILS_BY_ID_REQUEST';
export const GET_TICKET_DETAILS_BY_ID_SUCCESS = 'GET_TICKET_DETAILS_BY_ID_SUCCESS';
export const GET_TICKET_DETAILS_BY_ID_FAILURE = 'GET_TICKET_DETAILS_BY_ID_FAILURE';

export const ASSIGN_SP_MANUALLY_REQUEST = 'ASSIGN_SP_MANUALLY_REQUEST';
export const ASSIGN_SP_MANUALLY_FAILURE = 'ASSIGN_SP_MANUALLY_FAILURE';

export const REASSIGN_SP_MANUALLY_REQUEST = 'REASSIGN_SP_MANUALLY_REQUEST';
export const REASSIGN_SP_MANUALLY_FAILURE = 'REASSIGN_SP_MANUALLY_FAILURE';

export const ACCEPT_SP_MANUALLY_REQUEST = 'ACCEPT_SP_MANUALLY_REQUEST';
export const ACCEPT_SP_MANUALLY_FAILURE = 'ACCEPT_SP_MANUALLY_FAILURE';

export const WITHDRAW_TICKET_REQUEST = 'WITHDRAW_TICKET_REQUEST';
export const WITHDRAW_TICKET_FAILURE = 'WITHDRAW_TICKET_FAILURE';

export const SET_TICKET_FILTER_DATA = 'SET_TICKET_FILTER_DATA';

export const REFRESH_TICKET_TABLE_REQUEST = 'REFRESH_TICKET_TABLE_REQUEST';
export const REFRESH_TICKET_TABLE_SUCCESS = 'REFRESH_TICKET_TABLE_SUCCESS';
export const REFRESH_TICKET_TABLE_FAILURE = 'REFRESH_TICKET_TABLE_FAILURE';

export const REFRESH_TICKET_REQUEST = 'REFRESH_TICKET_REQUEST';
export const REFRESH_SP_TICKET_REQUEST = 'REFRESH_SP_TICKET_REQUEST';
export const REFRESH_FE_TICKET_REQUEST = 'REFRESH_FE_TICKET_REQUEST';

export const CLEANUP_PAYMENT_TICKET_DETAILS = 'CLEANUP_PAYMENT_TICKET_DETAILS';

export const START_TICKET_EXECUTION_REQUEST = 'START_TICKET_EXECUTION_REQUEST';
export const START_TICKET_EXECUTION_SUCCESS = 'START_TICKET_EXECUTION_SUCCESS';
export const START_TICKET_EXECUTION_FAILURE = 'START_TICKET_EXECUTION_FAILURE';

export const HOLD_TICKET_REQUEST = 'HOLD_TICKET_REQUEST';
export const HOLD_TICKET_SUCCESS = 'HOLD_TICKET_SUCCESS';
export const REPORT_PM = 'REPORT_PM';
export const HOLD_TICKET_FAILURE = 'HOLD_TICKET_FAILURE';

export const RESUME_TICKET_REQUEST = 'RESUME_TICKET_REQUEST';
export const RESUME_TICKET_FAILURE = 'RESUME_TICKET_FAILURE';
export const BULK_RESUME_TICKET_FAILURE = 'BULK_RESUME_TICKET_FAILURE';
export const BULK_RESUME_TICKET_REQUEST = 'BULK_RESUME_TICKET_REQUEST';

export const GET_SITE_BOQ_REQUEST = 'GET_SITE_BOQ_REQUEST';
export const GET_SITE_BOQ_SUCCESS = 'GET_SITE_BOQ_SUCCESS';
export const GET_SITE_BOQ_FAILURE = 'GET_SITE_BOQ_FAILURE';

export const SAVE_FE_SITE_BOQ_REQUEST = 'SAVE_FE_SITE_BOQ_REQUEST';
export const SAVE_FE_SITE_BOQ_SUCCESS = 'SAVE_FE_SITE_BOQ_SUCCESS';
export const SAVE_FE_SITE_BOQ_FAILURE = 'SAVE_FE_SITE_BOQ_FAILURE';

export const SAVE_FE_PRE_REQUISITE_REQUEST = 'SAVE_FE_PRE_REQUISITE_REQUEST';
export const SAVE_FE_PRE_REQUISITE_SUCCESS = 'SAVE_FE_PRE_REQUISITE_SUCCESS';
export const SAVE_FE_PRE_REQUISITE_FAILURE = 'SAVE_FE_PRE_REQUISITE_FAILURE';

export const GET_WORK_IN_PROGRESS_STEPS_REQUEST = 'GET_WORK_IN_PROGRESS_STEPS_REQUEST';
export const GET_WORK_IN_PROGRESS_STEPS_SUCCESS = 'GET_WORK_IN_PROGRESS_STEPS_SUCCESS';
export const GET_WORK_IN_PROGRESS_STEPS_FAILURE = 'GET_WORK_IN_PROGRESS_STEPS_FAILURE';

export const GET_WORK_IN_PROGRESS_STEPS_FIELDS_REQUEST = 'GET_WORK_IN_PROGRESS_STEPS_FIELDS_REQUEST';
export const GET_WORK_IN_PROGRESS_STEPS_FIELDS_SUCCESS = 'GET_WORK_IN_PROGRESS_STEPS_FIELDS_SUCCESS';
export const GET_WORK_IN_PROGRESS_STEPS_FIELDS_FAILURE = 'GET_WORK_IN_PROGRESS_STEPS_FIELDS_FAILURE';

export const UPDATE_WORK_IN_PROGRESS_STEP_DATA_REQUEST = 'UPDATE_WORK_IN_PROGRESS_STEP_DATA_REQUEST';
export const UPDATE_WORK_IN_PROGRESS_STEP_DATA_SUCCESS = 'UPDATE_WORK_IN_PROGRESS_STEP_DATA_SUCCESS';
export const UPDATE_WORK_IN_PROGRESS_STEP_DATA_FAILURE = 'UPDATE_WORK_IN_PROGRESS_STEP_DATA_FAILURE';

export const END_WORK_IN_PROGRESS_REQUEST = 'END_WORK_IN_PROGRESS_REQUEST';
export const END_WORK_IN_PROGRESS_SUCCESS = 'END_WORK_IN_PROGRESS_SUCCESS';

export const UPLOAD_SIGN_OFF_REPORT_REQUEST = 'UPLOAD_SIGN_OFF_REPORT_REQUEST';
export const UPLOAD_SIGN_OFF_REPORT_SUCCESS = 'UPLOAD_SIGN_OFF_REPORT_SUCCESS';
export const UPLOAD_SIGN_OFF_REPORT_FAILURE = 'UPLOAD_SIGN_OFF_REPORT_FAILURE';

export const UPDATE_RL_COURIER_DETAILS_REQUEST = 'UPDATE_RL_COURIER_DETAILS_REQUEST';
export const UPDATE_RL_COURIER_DETAILS_FAILURE = 'UPDATE_RL_COURIER_DETAILS_FAILURE';

export const TICKETS_PUT_TO_STORE_REQUEST = 'TICKETS_PUT_TO_STORE_REQUEST';
export const TICKETS_PUT_TO_STORE_SUCCESS = 'TICKETS_PUT_TO_STORE_SUCCESS';

export const TICKETS_LIST_REQUEST = 'TICKETS_LIST_REQUEST';
export const SP_ACCEPTED_TICKETS_LIST_REQUEST = 'SP_ACCEPTED_TICKETS_LIST_REQUEST';

export const INITIATE_TICKET_SUCCESS_IN_EXECUTION = 'INITIATE_TICKET_SUCCESS_IN_EXECUTION';

export const ASSIGN_TO_SCOGO_REQUEST = 'ASSIGN_TO_SCOGO_REQUEST';
export const ASSIGN_TO_SCOGO_FAILURE = 'ASSIGN_TO_SCOGO_FAILURE';

export const BULK_ASSIGN_TO_SCOGO_REQUEST = 'BULK_ASSIGN_TO_SCOGO_REQUEST';
export const BULK_ASSIGN_TO_SCOGO_FAILURE = 'BULK_ASSIGN_TO_SCOGO_FAILURE';

export const CHANGE_TICKET_WAREHOUSE_REQUEST = 'CHANGE_TICKET_WAREHOUSE_REQUEST';
export const CHANGE_TICKET_WAREHOUSE_FAILURE = 'CHANGE_TICKET_WAREHOUSE_FAILURE';

export const UPDATE_TICKET_RMA_DETAILS_REQUEST = 'UPDATE_TICKET_RMA_DETAILS_REQUEST';
export const UPDATE_TICKET_RMA_DETAILS_FAILURE = 'UPDATE_TICKET_RMA_DETAILS_FAILURE';

export const CUSTOMER_TICKET_SIGNOFF_REQUEST = 'CUSTOMER_TICKET_SIGNOFF_REQUEST';
export const CUSTOMER_TICKET_SIGNOFF_FAILURE = 'CUSTOMER_TICKET_SIGNOFF_FAILURE';

export const REJECT_TICKET_SIGNOFF_REQUEST = 'REJECT_TICKET_SIGNOFF_REQUEST';
export const REJECT_TICKET_SIGNOFF_FAILURE = 'REJECT_TICKET_SIGNOFF_FAILURE';

export const ACCEPT_REJECT_TICKET_ASSET_REQUEST = 'ACCEPT_REJECT_TICKET_ASSET_REQUEST';
export const ACCEPT_REJECT_TICKET_ASSET_FAILURE = 'ACCEPT_REJECT_TICKET_ASSET_FAILURE';

export const UPDATE_TICKET_DOCKET_DETAILS_REQUEST = 'UPDATE_TICKET_DOCKET_DETAILS_REQUEST';
export const UPDATE_TICKET_DOCKET_DETAILS_SUCCESS = 'UPDATE_TICKET_DOCKET_DETAILS_SUCCESS';
export const UPDATE_TICKET_DOCKET_DETAILS_FAILURE = 'UPDATE_TICKET_DOCKET_DETAILS_FAILURE';

export const CLOSE_FEASIBILITY_REQUEST = 'CLOSE_FEASIBILITY_REQUEST';
export const CLOSE_FEASIBILITY_FAILURE = 'CLOSE_FEASIBILITY_FAILURE';

export const CLEAR_SP_ASSIGNED_NEW_TICKETS_LIST = 'CLEAR_SP_ASSIGNED_NEW_TICKETS_LIST';

export const CLONE_TICKETS_REQUEST = 'CLONE_TICKETS_REQUEST';
export const CLONE_TICKETS_FAILURE = 'CLONE_TICKETS_FAILURE';

export const ASSIGN_TECH_SUPPORT_USERS_REQUEST = 'ASSIGN_TECH_SUPPORT_USERS_REQUEST';
export const ASSIGN_TECH_SUPPORT_USERS_SUCCESS = 'ASSIGN_TECH_SUPPORT_USERS_SUCCESS';
export const ASSIGN_TECH_SUPPORT_USERS_FAILURE = 'ASSIGN_TECH_SUPPORT_USERS_FAILURE';

export const UPDATE_CUSTOMER_APPROVAL_RECIEVED_REQUEST = 'UPDATE_CUSTOMER_APPROVAL_RECIEVED_REQUEST';
export const UPDATE_CUSTOMER_APPROVAL_RECIEVED_FAILURE = 'UPDATE_CUSTOMER_APPROVAL_RECIEVED_FAILURE';
export const CLEAR_VIEW_TICKET_FROM_STORE = 'CLEAR_VIEW_TICKET_FROM_STORE';

export const BULK_EDIT_EXECUTION_DATE_REQUEST = 'BULK_EDIT_EXECUTION_DATE_REQUEST';
export const BULK_EDIT_EXECUTION_DATE_SUCCESS = 'BULK_EDIT_EXECUTION_DATE_SUCCESS';
export const BULK_EDIT_EXECUTION_DATE_FAILURE = 'BULK_EDIT_EXECUTION_DATE_FAILURE';

export const APPROVE_OR_REJECT_PO_REQUEST = 'APPROVE_OR_REJECT_PO_REQUEST';
export const APPROVE_OR_REJECT_PO_FAILURE = 'APPROVE_OR_REJECT_PO_FAILURE';

export const REMOVE_PO_APPROVER_REQUEST = 'REMOVE_PO_APPROVER_REQUEST';
export const REMOVE_PO_APPROVER_SUCCESS = 'REMOVE_PO_APPROVER_SUCCESS';
export const REMOVE_PO_APPROVER_FAILURE = 'REMOVE_PO_APPROVER_FAILURE';

export const EDIT_TICKET_REQUEST = 'EDIT_TICKET_REQUEST';
export const EDIT_TICKET_SUCCESS = 'EDIT_TICKET_SUCCESS';
export const EDIT_TICKET_FAILURE = 'EDIT_TICKET_FAILURE';

export const CREATE_RMA_TICKET_REQUEST = 'CREATE_RMA_TICKET_REQUEST';
export const CREATE_RMA_TICKET_SUCCESS = 'CREATE_RMA_TICKET_SUCCESS';
export const CREATE_RMA_TICKET_FAILURE = 'CREATE_RMA_TICKET_FAILURE';

export const GET_TICKET_STATS_REQUEST = 'GET_TICKET_STATS_REQUEST';
export const GET_TICKET_STATS_SUCCESS = 'GET_TICKET_STATS_SUCCESS';
export const GET_TICKET_STATS_FAILURE = 'GET_TICKET_STATS_FAILURE';

export const EXPORT_TICKET_CSV_DATA_REQUEST = 'EXPORT_TICKET_CSV_DATA_REQUEST';

export const BULK_UPLOAD_SITE_REQUEST = 'BULK_UPLOAD_SITE_REQUEST';
export const BULK_UPLOAD_SITE_FAILURE = 'BULK_UPLOAD_SITE_FAILURE';

export const BULK_UPLOAD_SITE_MULTI_REQUEST = 'BULK_UPLOAD_SITE_MULTI_REQUEST';
export const BULK_UPLOAD_SITE_MULTI_FAILURE = 'BULK_UPLOAD_SITE_MULTI_FAILURE';

export const ADD_NEW_SITE_REQUEST = 'ADD_NEW_SITE_REQUEST';
export const ADD_NEW_SITE_SUCCESS = 'ADD_NEW_SITE_SUCCESS';
export const ADD_NEW_SITE_FAILURE = 'ADD_NEW_SITE_FAILURE';

export const RAISE_PO_REQUEST = 'RAISE_PO_REQUEST';
export const RAISE_PO_FAILURE = 'RAISE_PO_FAILURE';

export const GET_TICKETS_BY_IDS_REQUEST = 'GET_TICKETS_BY_IDS_REQUEST';
export const GET_TICKETS_BY_IDS_SUCCESS = 'GET_TICKETS_BY_IDS_SUCCESS';
export const GET_TICKETS_BY_IDS_FAILURE = 'GET_TICKETS_BY_IDS_FAILURE';

export const UPDATE_TICKET_PAYMENT_ALLOWANCE_REQUEST = 'UPDATE_TICKET_PAYMENT_ALLOWANCE_REQUEST';
export const UPDATE_TICKET_PAYMENT_ALLOWANCE_FAILURE = 'UPDATE_TICKET_PAYMENT_ALLOWANCE_FAILURE';
export const SET_TICKETS_SIDEDRAWER = 'SET_TICKETS_SIDEDRAWER';

export const GET_TICKETS_RESUME_FAILURE = 'GET_TICKETS_RESUME_FAILURE';

export const TICKET_DELETE_REQUEST = 'TICKET_DELETE_REQUEST';
export const TICKETS_DELETE_FAILURE = 'TICKETS_DELETE_FAILURE';

export const HARD_CLOSE_TICKET_REQUEST = 'HARD_CLOSE_TICKET_REQUEST';
export const TICKETS_HARD_CLOSE_FAILURE = 'TICKETS_HARD_CLOSE_FAILURE';

export const GET_PO_DETAILS = 'GET_PO_DETAILS';
export const PO_DETAILS_SUCCESS = 'PO_DETAILS_SUCCESS';
export const PO_DETAILS_FAILURE = 'PO_DETAILS_FAILURE';

export const REQUEST_FE_TRACK_IN_TICKET_FAILURE = 'REQUEST_FE_TRACK_IN_TICKET_FAILURE';

export const REQUEST_TRACK_FE = 'REQUEST_TRACK_FE';

export const ZIP_TICKET_REPORTS_REQUEST = 'ZIP_TICKET_REPORTS_REQUEST';
export const ZIP_TICKET_REPORTS_SUCCESS = 'ZIP_TICKET_REPORTS_SUCCESS';
export const ZIP_TICKET_REPORTS_FAILURE = 'ZIP_TICKET_REPORTS_FAILURE';

export const UPDATE_SIGN_OFF_NOTES_REQUEST = 'UPDATE_SIGN_OFF_NOTES_REQUEST';

export const REMOVE_PO_DETAILS_FROM_STORE = 'REMOVE_PO_DETAILS_FROM_STORE';

export const GET_TICKET_REMARKS = 'GET_TICKET_REMARKS';
export const GET_TICKET_REMARKS_SUCCESS = 'GET_TICKET_REMARKS_SUCCESS';
export const GET_TICKET_REMARKS_FAILURE = 'GET_TICKET_REMARKS_FAILURE';

export const GET_SIGN_OFF_STEPS_REQUEST = 'GET_SIGN_OFF_STEPS_REQUEST';
export const GET_SIGN_OFF_STEPS_SUCCESS = 'GET_SIGN_OFF_STEPS_SUCCESS';
export const GET_SIGN_OFF_STEP_VALUES_SUCCESS = 'GET_SIGN_OFF_STEP_VALUES_SUCCESS';
export const GET_SIGN_OFF_STEPS_FAILURE = 'GET_SIGN_OFF_STEPS_FAILURE';

export const UPDATE_SIGN_OFF_STEPS_REQUEST = 'UPDATE_SIGN_OFF_STEPS_REQUEST';
export const UPDATE_SIGN_OFF_STEPS_FAILURE = 'UPDATE_SIGN_OFF_STEPS_FAILURE';

export const CLEAR_TICKET_LIST = 'CLEAR_TICKET_LIST';

export const GET_TICKETS_COUNT_REQUEST = 'GET_TICKETS_COUNT_REQUEST';
export const GET_TICKETS_COUNT_SUCCESS = 'GET_TICKETS_COUNT_SUCCESS';
export const GET_TICKETS_COUNT_FAILURE = 'GET_TICKETS_COUNT_FAILURE';

export const ADD_MIGRATION_ASSETS_REQUEST = 'ADD_MIGRATION_ASSETS_REQUEST';
export const ADD_MIGRATION_ASSETS_SUCCESS = 'ADD_MIGRATION_ASSETS_SUCCESS';
export const ADD_MIGRATION_ASSETS_FAILURE = 'ADD_MIGRATION_ASSETS_FAILURE';

export const BULK_UPLOAD_TICKET_FILE_REQUEST = 'BULK_UPLOAD_TICKET_FILE_REQUEST';
export const BULK_UPLOAD_TICKET_FILE_SUCCESS = 'BULK_UPLOAD_TICKET_FILE_SUCCESS';
export const BULK_UPLOAD_TICKET_FILE_FAILURE = 'BULK_UPLOAD_TICKET_FILE_FAILURE';
export const SITE_COMPLETED_REQUEST = 'SITE_COMPLETED_REQUEST';

export const SAVE_REQUEST_ADDITIONAL_AMOUNT_REQUEST = 'SAVE_REQUEST_ADDITIONAL_AMOUNT_REQUEST';
export const REFRESH_TODAY_STATUS_TABLE_REQUEST = 'REFRESH_TODAY_STATUS_TABLE_REQUEST';

export const UPDATE_TICKET_TABLE_COURIER_STATUS_SUCCESS = 'UPDATE_TICKET_TABLE_COURIER_STATUS_SUCCESS';

export const ENGINEER_RATING_AND_COMMENT_REQUEST = 'ENGINEER_RATING_AND_COMMENT_REQUEST';
export const ENGINEER_RATING_AND_COMMENT_SUCCESS = 'ENGINEER_RATING_AND_COMMENT_SUCCESS';
export const ENGINEER_RATING_AND_COMMENT_FAILURE = 'ENGINEER_RATING_AND_COMMENT_FAILURE';

export const UPDATE_EXECUTION_STEP_DATA_REQUEST = 'UPDATE_EXECUTION_STEP_DATA_REQUEST';
export const UPDATE_EXECUTION_STEP_DATA_SUCCESS = 'UPDATE_EXECUTION_STEP_DATA_SUCCESS';
export const UPDATE_EXECUTION_STEP_DATA_FAILURE = 'UPDATE_EXECUTION_STEP_DATA_FAILURE';

export const UPDATE_VIEW_TICKET = 'UPDATE_VIEW_TICKET';
export const UPDATE_EXECUTION_STEP_FIELD_STATUS_FROM_WEBSOCKET = 'UPDATE_EXECUTION_STEP_FIELD_STATUS_FROM_WEBSOCKET';
export const UPDATE_VIEW_TICKET_FROM_WEBSOCKET = 'UPDATE_VIEW_TICKET_FROM_WEBSOCKET';
export const UPDATE_VIEW_TICKET_SIGNOFF_DOCUMENT = 'UPDATE_VIEW_TICKET_SIGNOFF_DOCUMENT';

export const ASK_APPOINTMENT_REQUEST = 'ASK_APPOINTMENT_REQUEST'
export const ASK_APPOINTMENT_SUCCESS = 'ASK_APPOINTMENT_SUCCESS'
export const ASK_APPOINTMENT_FAILURE = 'ASK_APPOINTMENT_FAILURE'
export const UNLOCK_SIGNOFF_REQUEST = 'UNLOCK_SIGNOFF_REQUEST';
export const UNLOCK_SIGNOFF_FAILURE = 'UNLOCK_SIGNOFF_SUCCESS';
export const UPDATE_TICKET_UNLOCK_SIGNOFF_STATUS_FROM_WEBSOCKET = 'UPDATE_TICKET_UNLOCK_SIGNOFF_STATUS_FROM_WEBSOCKET';
export const REMOVE_EXECUTPION_STEPS_FIELD_FROM_STORE = 'REMOVE_EXECUTPION_STEPS_FIELD_FROM_STORE';
export const NO_TICKET_FOUND = 'NO_TICKET_FOUND';

export const GET_ISSUE_CATEGORY_FILTER_REQUEST = 'GET_ISSUE_CATEGORY_FILTER_REQUEST'
export const GET_ISSUE_CATEGORY_FILTER_SUCCESS = 'GET_ISSUE_CATEGORY_FILTER_SUCCESS'
export const GET_ISSUE_CATEGORY_FILTER_FAILURE = 'GET_ISSUE_CATEGORY_FILTER_FAILURE'

export const GET_ACTIVE_VOICE_CALL_REQUEST = 'GET_ACTIVE_VOICE_CALL_REQUEST'
export const GET_ACTIVE_VOICE_CALL_SUCCESS = 'GET_ACTIVE_VOICE_CALL_SUCCESS'
export const GET_ACTIVE_VOICE_CALL_FAILURE = 'GET_ACTIVE_VOICE_CALL_FAILURE'
export const RECIEVED_ACTIVE_VOICE_CALL_FROM_WEBSOCKET = 'RECIEVED_ACTIVE_VOICE_CALL_FROM_WEBSOCKET'
export const VOICE_CALL_END_FROM_WEBSOCKET = 'VOICE_CALL_END_FROM_WEBSOCKET'

export const CREATE_SUPPORT_TRACK_TICKETS_REQUEST = 'CREATE_SUPPORT_TRACK_TICKETS_REQUEST';
export const CREATE_SUPPORT_TRACK_TICKETS_SUCCESS = 'CREATE_SUPPORT_TRACK_TICKETS_SUCCESS';
export const CREATE_SUPPORT_TRACK_TICKETS_FAILURE = 'CREATE_SUPPORT_TRACK_TICKETS_FAILURE';

export const MOVE_TO_RMA_REQUEST = 'MOVE_TO_RMA_REQUEST';
export const MOVE_TO_RMA_SUCCESS = 'MOVE_TO_RMA_SUCCESS';
export const MOVE_TO_RMA_FAILURE = 'MOVE_TO_RMA_FAILURE';

export const BULK_HARDCOPY_RECIEVED_UPDATE_REQUEST = 'BULK_HARDCOPY_RECIEVED_UPDATE_REQUEST';
export const BULK_HARDCOPY_RECIEVED_UPDATE_FAILURE = 'BULK_HARDCOPY_RECIEVED_UPDATE_FAILURE';

export const UPDATE_TICKET_SITE_PO_SUCCESS = 'UPDATE_TICKET_SITE_PO_SUCCESS';
export const UPDATED_SITE_DETAILS_WHEN_EDIT_TICKET = 'UPDATED_SITE_DETAILS_WHEN_EDIT_TICKET';
export const GET_WORK_IN_PROGRESS_CHAT_HEADER_STEPS_FIELDS_SUCCESS = 'GET_WORK_IN_PROGRESS_CHAT_HEADER_STEPS_FIELDS_SUCCESS';

export const CALL_ON_COPY_TICKET_DETAILS_REQUEST = 'CALL_ON_COPY_TICKET_DETAILS_REQUEST';
export const CALL_ON_COPY_TICKET_DETAILS_FAILURE = 'CALL_ON_COPY_TICKET_DETAILS_FAILURE';

export const UPDATE_REACHED_SITE_IN_TICKET_DETAILS = 'UPDATE_REACHED_SITE_IN_TICKET_DETAILS';

export const CNERGEE_ASSET_DEVICE_DETAILS_REQUEST = 'CNERGEE_ASSET_DEVICE_DETAILS_REQUEST';
export const CNERGEE_ASSET_DEVICE_DETAILS_SUCCESS = 'CNERGEE_ASSET_DEVICE_DETAILS_SUCCESS';



export const NOTIFY_TICKET_DETAILS_TO_END_CUSTOMER_REQUEST = 'NOTIFY_TICKET_DETAILS_TO_END_CUSTOMER_REQUEST';
export const NOTIFY_TICKET_DETAILS_TO_END_CUSTOMER_SUCCESS = 'NOTIFY_TICKET_DETAILS_TO_END_CUSTOMER_SUCCESS';
export const NOTIFY_TICKET_DETAILS_TO_END_CUSTOMER_FAILURE = 'NOTIFY_TICKET_DETAILS_TO_END_CUSTOMER_FAILURE';


export const ASSIGN_TICKET_OWNERSHIP_REQUEST = 'ASSIGN_TICKET_OWNERSHIP_REQUEST';


export const GET_POSSIBLE_TICKET_OWNERS_REQUEST = 'GET_POSSIBLE_TICKET_OWNERS_REQUEST';
export const GET_POSSIBLE_TICKET_OWNERS_SUCCESS = 'GET_POSSIBLE_TICKET_OWNERS_SUCCESS';
export const GET_POSSIBLE_TICKET_OWNERS_FAILURE = 'GET_POSSIBLE_TICKET_OWNERS_FAILURE';

import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Input from '../../../common/Form/Input';
import { notifyTicketDetailsToEndCustomerAction } from '../../../actions/tickets';

const formName = 'NotifyTicketDetailsToEndCustomer';

export default function NotifyTicketDetailsToEndCustomer({ mobile, ticketId, job_ticket_number, status, address, site_id }) {
    const defaultValues = { mobile: mobile && mobile.length === 10 ? mobile : '' };
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);

    const submit = (formValues) => {

        const payload = {
            fk_customer_id: 1,
            stage: 'TICKET_DETAILS',
            ticket_id: ticketId,
            job_ticket_number,
            sender: formValues.mobile.startsWith('91') && formValues.mobile.length === 10 ? formValues.mobile : `91${formValues.mobile}`,
            formName,
            status,
            address,
            site_id
        }
        dispatch(notifyTicketDetailsToEndCustomerAction(payload));
    };


    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-2'>
                <Input
                    label='Mobile'
                    type={'number'}
                    name='mobile' required validate={(value) => {
                        if (value === '') return true;
                        return value.toString().length === 10 || 'Invalid Mobile';
                    }}
                />
            </div>
        </Form>
    );
}
